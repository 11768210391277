import React, { Component } from "react"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import Slider from "react-slick"

class GaleriaLast extends Component {
  state = {
    photoIndex: 0,
    isOpen: false,
  }

  settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
    ],
  }

  render() {
    const { isOpen, photoIndex } = this.state

    const photo = this.props.img.map((item, key) => {
      return (
        <div key={key}
        // onKeyUp={() => console.log("key up")}
          role="button"
          tabIndex="0"
          onKeyPress={() => this.setState({ isOpen: true, photoIndex: key })}
          onClick={() => this.setState({ isOpen: true, photoIndex: key })}
        >
          {/* <a
            onKeyDown={() => console.log("key up")}
          > */}
          <img
            className="img-carousel"
            src={item}
            alt="zdjecie w galerii"
          />
          {/* </a> */}
        </div>
      )
    })

    return (
      <div className="content gallery-slick">
        <Slider {...this.settings}>{photo}</Slider>
        {isOpen && (
          <Lightbox
            mainSrc={this.props.img[photoIndex]}
            nextSrc={this.props.img[(photoIndex + 1) % this.props.img.length]}
            prevSrc={
              this.props.img[
              (photoIndex + this.props.img.length - 1) % this.props.img.length
              ]
            }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (photoIndex + this.props.img.length - 1) %
                  this.props.img.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % this.props.img.length,
              })
            }
          />
        )}
      </div>
    )
  }
}

export default GaleriaLast
