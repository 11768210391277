import React from 'react';
import Layout from '../../layout/pl';
import TeamPerson from '../../components/TeamPerson';

import Elwira from '../../images/team/elwira-small.png';

const handleClick = () => {
	window.medicall_modal('f78b07a362fecf4542fa', 1);
	return false;
};

const certification = [];
const ElwiraZawislak = (props) => {
	return (
		<Layout {...props}>
			<TeamPerson img={Elwira} handleClick={handleClick} certification={certification} person="elwira" />
		</Layout>
	);
};
export default ElwiraZawislak;
