import React from 'react';
import { FormattedMessage } from 'react-intl';
import TitleHeader from '../components/TitleHeader';
import GalleryComponent from '../components/GalleryComponent';

const TeamPerson = ({ certification, img, handleClick, person }) => {
	// console.log("TeamPerson",props);
	console.log('TeamPerson', certification, certification.length, img, handleClick, person);
	return (
		<div>
			<FormattedMessage id={`menu.team.${person}`}>{(txt) => <TitleHeader title={txt} />}</FormattedMessage>
			<div className="content zespol-template">
				<div className="img-contener">
					<img src={img} className="img" alt={person} />
				</div>

				<div className="text-contener">
					<FormattedMessage id={`menu.team.${person}`}>{(txt) => <h1>{txt}</h1>}</FormattedMessage>
					<FormattedMessage id={`team.${person}.specialist`}>
						{(txt) => <p className="under-h1">{txt}</p>}
					</FormattedMessage>
					<img
						src="https://sztukastomatologii.pl/wp-content/uploads/2018/07/zapraszam-300x89.png"
						alt="podpis"
						className="img podpis"
					/>
					<FormattedMessage id={`team.${person}.description`}>{(txt) => <p>{txt}</p>}</FormattedMessage>

					<button className="btn btn-register" type="button" onClick={handleClick}>
						<FormattedMessage id="menu.register.now" />
					</button>
				</div>
			</div>
			{certification.length > 0 ? <GalleryComponent className="content" img={certification} /> : <div />}
		</div>
	);
};

export default TeamPerson;
